import { useContext, useState, type CSSProperties } from 'react';
import { Button, TextField } from '@cimpress/react-components';
import type { CimpressDocument, DesignDocumentWithPanels } from '@mcp-artwork/cimdoc-types-v2';
import { FusionCanvas } from '@rendering/fusion-react';
import { Page } from '../../Components';
import { getDeductiveDesign, type DeductiveDesignContextMessage } from '../../api/deductiveDesign';
import { ApiKeyContext } from '../../App';
import { getPhotoSceneUrl, getTransientDocumentUrl, type ExperimentalDebugInfo } from '../../api';
import {
    getMpvIdFromProductName,
    getProductKeyFromProductName,
    getSurfaceSkuForProduct,
    getSurfaceSkuVariablesForProduct,
    getSurfaceUrlForProduct,
} from './ProductMapping';

type Message =
    | {
          role: 'user';
          content: string;
          isCustomerProductSelection: boolean;
      }
    | {
          role: 'assistant';
          documents: CimpressDocument[];
          debugInfo: NonNullable<ExperimentalDebugInfo['generateDeductiveDesign']>;
          sceneRenderingUrls?: string[];
      }
    | {
          role: 'assistant';
          error: string;
          productSelection?: true;
          debugInfo?: NonNullable<ExperimentalDebugInfo['generateDeductiveDesign']>;
      };

type Product = { value: string; label: string };

const AVAILABLE_PRODUCTS: Product[] = [
    { value: 'businesscards', label: 'Business cards' },
    { value: 'mousepads', label: 'Mousepads' },
    { value: 'envelopeseals', label: 'Envelope seals' },
    { value: 'notebooks', label: 'Notebooks' },
    { value: 'productlabels', label: 'Product labels' },
    { value: 'caps', label: 'Caps' },
    { value: 'carmagnets', label: 'Car magnets' },
    { value: 'cardecals', label: 'Car decals' },

    // TODO: Fix scene not showing
    // { value: 'notepads', label: 'Notepads' },    Design covering whole product

    // Fix later, contains unchangeable text in layout, not looking good for demo
    // { value: 'flyers', label: 'Flyers' },
    // { value: 'giftcertificates', label: 'Gift certificates' },
    // { value: 'postcards', label: 'Postcards' },

    // TODO: Scenes are stretched, fix later if time allows
    // { value: 'yardsigns', label: 'Yard signs' },
    // { value: 'rolllabels', label: 'Roll labels' },
    // { value: 'mugs', label: 'Mugs' },
    // { value: 'pens', label: 'Pens' },
    // { value: 'notecards', label: 'Notecards' },
    // { value: 'stamps', label: 'Stamps' },
    // { value: 'tshirts', label: 'T-shirts' },

    // TODO: layouts cannot be found for these products, also see https://cimpress.slack.com/archives/C057277VB19/p1740734730913539
    // { value: 'sheetstickers', label: 'Sheet stickers' },
    // { value: 'paperbags', label: 'Paper bags' },
    // { value: 'totebags', label: 'Tote bags' },
] as const;

const copyPayload = (getDeductiveDesignMessages: DeductiveDesignContextMessage[]) =>
    navigator.clipboard.writeText(
        JSON.stringify({
            freeTextDecoder: getDeductiveDesignMessages.map(({ role, content }) =>
                role === 'user'
                    ? { role, content }
                    : {
                          role,
                          content: JSON.stringify(content.freeTextDecoderResult),
                      },
            ),
            tags: getDeductiveDesignMessages
                .filter(({ shouldNotBeTagged }) => !shouldNotBeTagged)
                .map(({ role, content }) =>
                    role === 'user'
                        ? { role, content }
                        : {
                              role,
                              content: JSON.stringify(content.textClassifyTagsResult),
                          },
                ),
        }),
    );

const getStudioUrl = async (document: CimpressDocument, productName: string) => {
    const sku = getSurfaceSkuForProduct(productName.toLowerCase());
    if (!sku) {
        console.error('No SKU found for product:', productName);
        return null;
    }
    const [productKeyVp, mpvId] = [getProductKeyFromProductName(productName), getMpvIdFromProductName(productName)];

    const skuVariables = getSurfaceSkuVariablesForProduct(productName.toLowerCase());
    const transientDocumentUrl = getTransientDocumentUrl(document);

    const studioUrl = productKeyVp
        ? `https://www.vistaprint.com/studio/?key=${productKeyVp}&locale=en-us&mpvId=${mpvId}&selectedOptions=${encodeURIComponent(
              JSON.stringify(skuVariables),
          )}&fullBleedElected=true&documentUrl=${encodeURIComponent(transientDocumentUrl)}`
        : '';

    return studioUrl;
};

const removeSkuFromDocument = (doc: CimpressDocument): CimpressDocument => {
    const cleanDoc = JSON.parse(JSON.stringify(doc));
    if (cleanDoc.sku && cleanDoc.skuVariables) {
        delete cleanDoc.sku;
        delete cleanDoc.skuVaribles;
    }
    return cleanDoc;
};

const getDeductiveDesignMessages = (messages: Message[]) =>
    messages
        .map((m) => {
            if (m.role === 'user')
                return { role: 'user', content: m.content, shouldNotBeTagged: m.isCustomerProductSelection };
            if (m.role === 'assistant' && (!('error' in m) || 'productSelection' in m)) {
                return {
                    role: 'assistant',
                    content: {
                        freeTextDecoderResult: m.debugInfo?.freeTextDecoderResult,
                        textClassifyTagsResult: m.debugInfo?.textClassifyTagsResult,
                    },
                    shouldNotBeTagged: 'productSelection' in m && m.productSelection,
                };
            }
            return undefined;
        })
        .filter((m): m is DeductiveDesignContextMessage => m !== undefined);

export const DeductiveDesignDemoPage = () => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const apiKey = useContext(ApiKeyContext);

    const handleProductSelect = (product: Product) => {
        const message = `I want this design on product ${product.value}`;
        setInput(message);
        handleSubmit(message, product.value);
    };

    const handleSubmit = async (input: string, targetProductName?: string) => {
        if (!input.trim()) return;

        const userMessage: Message = {
            role: 'user',
            content: input,
            isCustomerProductSelection: !!targetProductName,
        };

        setMessages((prev) => [...prev, userMessage]);
        setInput('');
        setIsLoading(true);

        try {
            const { documents: deductiveDesignDocuments, debugInfo } = await getDeductiveDesign({
                input: getDeductiveDesignMessages([...messages, userMessage]),
                targetProductName,
                apiKey,
            });

            if (!debugInfo.chosenProductName) {
                setMessages((prev) => [
                    ...prev,
                    {
                        role: 'assistant',
                        error: 'Please select a product from the list below:',
                        debugInfo,
                        productSelection: true,
                    },
                ]);
                setIsLoading(false);
                return;
            }

            const surfaceUrl = getSurfaceUrlForProduct(debugInfo.chosenProductName);

            const sceneRenderingUrls: string[] = [];
            const getSceneUrlPromises = deductiveDesignDocuments.map(async (document, i) => {
                const sceneUrl = await getPhotoSceneUrl(surfaceUrl, undefined);
                const transientDocumentUrl = getTransientDocumentUrl(document, true);
                const sceneRenderingUrl = `https://rendering.documents.cimpress.io/v1/cse/preview?width=800&instructions_uri=${encodeURIComponent(
                    transientDocumentUrl,
                )}&scene=${encodeURIComponent(sceneUrl as string)}`;

                // Check if the scene rendering URL is valid to prevent the alert triangle from showing
                try {
                    const response = await fetch(sceneRenderingUrl);
                    if (response.status === 200) {
                        sceneRenderingUrls[i] = sceneRenderingUrl;
                    }
                } catch (error) {
                    console.warn('Failed to validate scene rendering URL:', error);
                }
            });

            await Promise.all(getSceneUrlPromises);

            setMessages((prev) => [
                ...prev,
                {
                    role: 'assistant',
                    documents: deductiveDesignDocuments,
                    debugInfo,
                    sceneRenderingUrls,
                },
            ]);
        } catch (error) {
            setMessages((prev) => [
                ...prev,
                {
                    role: 'assistant',
                    error: 'Sorry, there was an error processing your request.',
                },
            ]);
        } finally {
            setIsLoading(false);
        }
    };

    const messagesWithoutUserProductSelection = messages.filter(
        (m) => m.role === 'assistant' || !m.isCustomerProductSelection,
    );

    return (
        <Page>
            <Page.Title>Deductive Design</Page.Title>
            <Page.Content fullWidth>
                <div style={{ display: 'flex', gap: '16px', justifyContent: 'space-between' }}>
                    <div>
                        <p>
                            Start by typing a message to the assistant. The assistant will then generate a design for
                            you.
                        </p>
                        <br />
                        <b>Example prompts:</b>
                        <ul style={INTRO_LIST_STYLE}>
                            <li>
                                I need a design for a notebook cover featuring a vintage map design with the text
                                'Wander often, wonder always.'
                            </li>
                            <li>I need a cap with a motivational theme and the text 'Believe in yourself.'</li>
                            <li>I want a mousepad with a retro style and the text 'Live fast, drive slow.'</li>
                        </ul>
                    </div>
                    <Button
                        style={{ alignSelf: 'flex-start' }}
                        onClick={() => copyPayload(getDeductiveDesignMessages(messages))}
                    >
                        Copy payload
                    </Button>
                </div>
                <div style={MESSAGE_CONTAINER_STYLE}>
                    {messagesWithoutUserProductSelection.map((message, index) => {
                        if (message.role === 'user') {
                            return <UserMessage key={index} message={message} />;
                        }

                        return (
                            <AssistantMessage
                                key={index}
                                message={message}
                                handleProductSelect={handleProductSelect}
                                isLastMessage={index === messages.length - 1}
                            />
                        );
                    })}
                    {isLoading && <LoadingMessage />}
                </div>

                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(input);
                    }}
                    style={FORM_STYLE}
                >
                    <TextField
                        label="Type your message..."
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        style={{ flex: 1 }}
                        autoFocus
                    />
                    <Button disabled={isLoading} style={isLoading ? BUTTON_DISABLED_STYLE : undefined}>
                        {isLoading ? 'Sending...' : 'Send'}
                    </Button>
                </form>
            </Page.Content>
        </Page>
    );
};

const UserMessage = ({ message }: { message: Message }) => {
    if (message.role !== 'user') return null;

    return (
        <div style={{ alignSelf: 'flex-end', maxWidth: '90%' }}>
            <div style={USER_MESSAGE_STYLE}>{message.content}</div>
        </div>
    );
};

const LoadingMessage = () => {
    return (
        <div style={{ alignSelf: 'flex-start' }}>
            <div style={ASSISTANT_MESSAGE_STYLE}>Loading...</div>
        </div>
    );
};

const AssistantMessage = ({
    message,
    handleProductSelect,
    isLastMessage,
}: {
    message: Message;
    handleProductSelect: (product: Product) => void;
    isLastMessage: boolean;
}) => {
    const [showScene, setShowScene] = useState(true);
    const [showAllDesigns, setShowAllDesigns] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean[]>([]);

    if (message.role !== 'assistant') return null;

    const setDocumentLoading = (index: number, loading: boolean) => {
        if (loading === isLoading[index]) return;
        setIsLoading((prev) => {
            const newLoading = [...prev];
            newLoading[index] = loading;
            return newLoading;
        });
    };

    const isAnyDocumentLoading = isLoading.some((loading) => loading);

    return (
        <div style={{ alignSelf: 'flex-start', maxWidth: '70%' }}>
            <div style={ASSISTANT_MESSAGE_STYLE}>
                {'error' in message ? (
                    <div>
                        <span style={{ marginBottom: '12px' }}>{message.error}</span>
                        {(message as any).productSelection && (
                            <div style={PRODUCT_LIST_STYLE}>
                                {AVAILABLE_PRODUCTS.map((product) => (
                                    <Button
                                        key={product.value}
                                        onClick={() => handleProductSelect(product)}
                                        style={PRODUCT_BUTTON_STYLE}
                                        disabled={!isLastMessage}
                                    >
                                        {product.label}
                                    </Button>
                                ))}
                            </div>
                        )}
                    </div>
                ) : (
                    <div style={{ maxHeight: '100%' }}>
                        <div
                            style={{
                                marginBottom: '8px',
                                display: 'flex',
                                gap: '8px',
                            }}
                        >
                            {((showAllDesigns && !!message.sceneRenderingUrls?.length) ||
                                (!showAllDesigns && !!message.sceneRenderingUrls?.[0])) && (
                                <Button onClick={() => setShowScene(!showScene)} style={VIEW_TOGGLE_BUTTON_STYLE}>
                                    Show {showScene ? 'Design' : 'Scene'}
                                </Button>
                            )}

                            <Button onClick={() => setShowAllDesigns(!showAllDesigns)} style={VIEW_TOGGLE_BUTTON_STYLE}>
                                Show {showAllDesigns ? 'Single Design' : 'Multiple Designs'}
                            </Button>
                        </div>
                        {isAnyDocumentLoading && <div>Loading...</div>}
                        <div
                            style={{
                                ...getDocumentsGridStyle(showAllDesigns ? 4 : 1),
                                display: isAnyDocumentLoading ? 'none' : 'grid',
                            }}
                        >
                            {message.documents.slice(0, 4).map((document, index) => {
                                const shouldShowScene =
                                    showScene && message.sceneRenderingUrls && message.sceneRenderingUrls[index];

                                return (
                                    <div
                                        key={index}
                                        style={{
                                            ...DOCUMENT_CELL_STYLE,
                                            display: showAllDesigns || index === 0 ? 'block' : 'none',
                                        }}
                                    >
                                        {showAllDesigns && <div style={DOCUMENT_NUMBER_STYLE}>{index + 1}</div>}
                                        <button
                                            style={{
                                                ...ASSISTANT_DESIGN_RESPONSE_STYLE,
                                                display: isLoading[index] ? 'none' : 'flex',
                                                height: '100%',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                if (!message.debugInfo.chosenProductName) {
                                                    console.error('No product name found');
                                                    return;
                                                }
                                                const studioUrl = await getStudioUrl(
                                                    document,
                                                    message.debugInfo.chosenProductName,
                                                );
                                                if (studioUrl) {
                                                    window.open(studioUrl, '_blank');
                                                } else {
                                                    console.error('Failed to generate studio URL');
                                                }
                                            }}
                                        >
                                            <button
                                                style={{
                                                    all: 'unset',
                                                    position: 'absolute',
                                                    top: 4,
                                                    right: 4,
                                                    width: '16px',
                                                    height: '16px',
                                                    borderRadius: '50%',
                                                    padding: '4px',
                                                    backgroundColor: 'rgb(0, 136, 169)',
                                                }}
                                                onClick={async (e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();

                                                    const cimdocUrl = getTransientDocumentUrl(document);
                                                    window.open(cimdocUrl, '_blank');
                                                }}
                                            >
                                                <svg
                                                    style={{ padding: '2px', fill: 'white' }}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    shapeRendering="geometricPrecision"
                                                    textRendering="geometricPrecision"
                                                    imageRendering="optimizeQuality"
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    viewBox="0 0 509 511.54"
                                                >
                                                    <path
                                                        fill-rule="nonzero"
                                                        d="M447.19 347.03c0-17.06 13.85-30.91 30.91-30.91 17.05 0 30.9 13.85 30.9 30.91v87.82c0 21.08-8.63 40.29-22.51 54.18-13.88 13.88-33.09 22.51-54.18 22.51H76.69c-21.09 0-40.3-8.63-54.18-22.51C8.63 475.14 0 455.93 0 434.85V76.69c0-21.09 8.63-40.3 22.51-54.18C36.39 8.63 55.6 0 76.69 0h86.98c17.06 0 30.9 13.85 30.9 30.9 0 17.06-13.84 30.91-30.9 30.91H76.69c-4.07 0-7.82 1.69-10.51 4.37-2.68 2.69-4.37 6.44-4.37 10.51v358.16c0 4.06 1.69 7.82 4.37 10.5 2.69 2.68 6.44 4.38 10.51 4.38h355.62c4.07 0 7.82-1.7 10.51-4.38 2.68-2.68 4.37-6.44 4.37-10.5v-87.82zm0-243.56L308.15 244.28c-11.91 12.12-31.45 12.28-43.56.37-12.11-11.91-12.28-31.45-.37-43.56L401.77 61.81H309.7c-17.06 0-30.9-13.85-30.9-30.91 0-17.05 13.84-30.9 30.9-30.9h168.4C495.15 0 509 13.85 509 30.9v165.04c0 17.06-13.85 30.9-30.9 30.9-17.06 0-30.91-13.84-30.91-30.9v-92.47z"
                                                    />
                                                </svg>
                                            </button>
                                            <img
                                                src={message.sceneRenderingUrls?.[index] ?? ''}
                                                style={{
                                                    maxWidth: '100%',
                                                    maxHeight: showAllDesigns ? '300px' : '500px',
                                                    display: shouldShowScene ? 'block' : 'none',
                                                }}
                                            />
                                            <FusionCanvas
                                                cimDoc={removeSkuFromDocument({ ...document })}
                                                dimension={{
                                                    height: 1000,
                                                }}
                                                textOptions={{ rtextEnabled: false }}
                                                selector={{
                                                    type: 'panel',
                                                    id: (document.document as DesignDocumentWithPanels).panels![0].id,
                                                }}
                                                onLoading={(loading) => {
                                                    setDocumentLoading(index, loading);
                                                }}
                                                onPaint={() => {}}
                                                onError={(error) => {
                                                    console.error('FusionCanvas error:', error);
                                                }}
                                                referrer=""
                                                style={{
                                                    maxWidth: '100%',
                                                    maxHeight: showAllDesigns ? '300px' : '500px',
                                                    display: shouldShowScene ? 'none' : 'block',
                                                }}
                                            />
                                        </button>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const INTRO_LIST_STYLE: CSSProperties = {
    marginLeft: '20px',
};

const MESSAGE_CONTAINER_STYLE: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginBottom: '16px',
};

const USER_MESSAGE_STYLE: CSSProperties = {
    alignSelf: 'flex-end',
    backgroundColor: 'rgb(0, 136, 169)',
    color: 'white',
    padding: '12px 16px',
    borderRadius: '16px 16px 4px 16px',
    minWidth: '100px',
    width: 'fit-content',
    whiteSpace: 'pre-wrap',
};

const ASSISTANT_MESSAGE_STYLE: CSSProperties = {
    alignSelf: 'flex-start',
    backgroundColor: '#ddd',
    padding: '16px',
    borderRadius: '16px 16px 16px 4px',
    marginBottom: '8px',
    width: 'fit-content',
    maxWidth: '100%',
    whiteSpace: 'pre-wrap',
};

const FORM_STYLE: CSSProperties = {
    display: 'flex',
    gap: '8px',
    width: '100%',
};

const BUTTON_DISABLED_STYLE: CSSProperties = {
    backgroundColor: '#ccc',
    cursor: 'not-allowed',
};

const PRODUCT_LIST_STYLE: CSSProperties = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    marginTop: '12px',
};

const PRODUCT_BUTTON_STYLE: CSSProperties = {
    width: 'fit-content',
    backgroundColor: '#f0f0f0',
    color: '#333',
    border: '1px solid #ccc',
};

const VIEW_TOGGLE_BUTTON_STYLE: CSSProperties = {
    backgroundColor: '#f0f0f0',
    color: '#333',
    border: '1px solid #ccc',
    padding: '4px 8px',
    fontSize: '14px',
};

const ASSISTANT_DESIGN_RESPONSE_STYLE: CSSProperties = {
    width: '100%',
    padding: '0',
    border: 'none',
    cursor: 'pointer',
    transition: 'transform 0.2s ease',
};

const getDocumentsGridStyle = (itemCount: number) => ({
    gridTemplateColumns: itemCount === 1 ? '1fr' : 'repeat(2, 1fr)',
    gap: '12px',
    maxWidth: '100%',
    maxHeight: '100%',
});

const DOCUMENT_CELL_STYLE: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    overflow: 'hidden',
    position: 'relative',
};

const DOCUMENT_NUMBER_STYLE: CSSProperties = {
    position: 'absolute',
    top: '4px',
    left: '4px',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    backgroundColor: '#0088a9',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    fontWeight: 'bold',
    zIndex: 1,
};
