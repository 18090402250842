import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface UseConvertRasterToVectorParams {
    imageUrl: string | null;
    setImageUrl: (url: string) => void;
}

export const useConvertRasterToVectorParams = (): UseConvertRasterToVectorParams => {
    const [searchParams, setSearchParams] = useSearchParams();
    const imageUrlParam = searchParams.get('imageUrl');
    const [imageUrl, setImageUrl] = useState(imageUrlParam ?? '');

    useEffect(() => {
        const searchParamsObj: Record<string, string | string[]> = {};

        if (imageUrl) {
            searchParamsObj['imageUrl'] = imageUrl;
        }

        setSearchParams(searchParamsObj);
    }, [imageUrl, setSearchParams]);

    return {
        imageUrl,
        setImageUrl,
    };
};
