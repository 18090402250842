import { FourOhFour } from '@cimpress/react-components';
import { type JSX, Suspense } from 'react';
import { AdjustForSubstratePage } from '../Pages/AdjustForSubstratePage';
import { AdjustForSurfacePage } from '../Pages/AdjustForSurfacePage';
import { CompositePage } from '../Pages/CompositePage';
import { DeductiveDesignDemoPage } from '../Pages/DeductiveDesignDemo/DeductiveDesignDemoPage';
import { FontSwapPage } from '../Pages/FontSwapPage';
import GenerateImageFromText from '../Pages/GenerateImageFromText';
import { ConvertRasterToVector } from '../Pages/ConvertRasterToVector';
import { GeneratePage } from '../Pages/GeneratePage';
import { ImproveArtworkContrastPage } from '../Pages/ImproveArtworkContrastPage';
import { InspirationGeneratePage } from '../Pages/InspirationGeneratePage';
import { ResizePage } from '../Pages/ResizePage';
import { TransferCustomization } from '../Pages/TransferCustomizationPage';
import { TransferStyle } from '../Pages/TransferStylePage';
import { HiddenPageIndexPage, PersonalizedGallery, PreviewCardVisualizationsPage, SettingsPage } from './lazyLoaders';
import { PageLoadFallback } from './PageLoadFallback';
export interface Route {
    href: string;
    title: string;
    element: JSX.Element;
}

export interface RouteWithProblemSpace extends Route {
    problemSpace: string;
}

export interface ProblemSpaceRoutes {
    name: string;
    routes: Route[];
}

const artworkAdaptationRoutes: Route[] = [
    {
        href: 'resize',
        title: 'Resize',
        element: <ResizePage />,
    },
    {
        href: 'adjustforsubstrate',
        title: 'Adapt to Substrate Color',
        element: <AdjustForSubstratePage />,
    },
    {
        href: 'adjustforsurface',
        title: 'Adapt to Product Surface',
        element: <AdjustForSurfacePage />,
    },
];

const artworkCompositionRoutes: Route[] = [
    {
        href: 'generate',
        title: 'Generate',
        element: <GeneratePage />,
    },
    {
        href: 'composite',
        title: 'Compose',
        element: <CompositePage />,
    },
    {
        href: 'fontswap',
        title: 'Font Swap',
        element: <FontSwapPage />,
    },
];

const artworkPreservationRoutes: Route[] = [
    {
        href: 'transfer',
        title: 'Transfer Customizations',
        element: <TransferCustomization />,
    },
    {
        href: 'transferstyle',
        title: 'Transfer Style',
        element: <TransferStyle />,
    },
];

const artworkQualityRoutes: Route[] = [
    {
        href: 'convertrastertovector',
        title: 'Convert Raster To Vector',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <ConvertRasterToVector />
            </Suspense>
        ),
    },
    {
        href: 'improveartworkcontrast',
        title: 'Improve Artwork Contrast',
        element: <ImproveArtworkContrastPage />,
    },
];

const artworkInspirationRoutes: Route[] = [
    {
        href: 'inspirationgenerate',
        title: 'Generate',
        element: <InspirationGeneratePage />,
    },
    {
        href: 'generate-image-from-text',
        title: 'Generate Image From Text',
        element: <GenerateImageFromText />,
    },
    {
        href: 'deductive-design-demo',
        title: 'Deductive Design Demo',
        element: <DeductiveDesignDemoPage />,
    },
];

export const hiddenRoutes: Route[] = [
    {
        href: 'hiddenpages',
        title: 'Hidden pages',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <HiddenPageIndexPage />
            </Suspense>
        ),
    },
    {
        href: 'settings',
        title: 'Settings',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <SettingsPage />
            </Suspense>
        ),
    },
    {
        href: 'personalized-gallery',
        title: 'Personalized Gallery',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <PersonalizedGallery />
            </Suspense>
        ),
    },
    {
        href: 'previewCards',
        title: 'PreviewCard Visualizations',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <PreviewCardVisualizationsPage />
            </Suspense>
        ),
    },
    {
        href: '*',
        title: '404 - Not Found',
        element: (
            <FourOhFour message="Oops! The page you are looking for doesn't exist. Please check the URL or go back to the homepage." />
        ),
    },
];

export const routesByProblemSpace: ProblemSpaceRoutes[] = [
    {
        name: 'Artwork Adaptation',
        routes: artworkAdaptationRoutes,
    },
    {
        name: 'Artwork Composition',
        routes: artworkCompositionRoutes,
    },
    {
        name: 'Artwork Preservation',
        routes: artworkPreservationRoutes,
    },
    {
        name: 'Artwork Quality',
        routes: artworkQualityRoutes,
    },
    {
        name: 'Artwork Inspiration',
        routes: artworkInspirationRoutes,
    },
];

export const routes: RouteWithProblemSpace[] = routesByProblemSpace.flatMap(({ name, routes }) =>
    routes.map((route) => ({ ...route, problemSpace: name })),
);
