import { Button } from '@cimpress/react-components';
import IconRemove from '@cimpress-technology/react-streamline-icons/lib/IconRemove';
import { type ChangeEvent, useRef, useState } from 'react';
import { uploadImageAndGetImageUrl } from '../../../api';
import { Control } from '../../layout';
import { FileDropArea } from './FileDropArea';

import classes from './DragDropImageUploader.module.css';

const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml', 'application/pdf'];
const FILE_INPUT_ACCEPT = ALLOWED_FILE_TYPES.join(',');

interface DragDropImageUploaderProps {
    onUploadingStateChange: (isUploading: boolean) => void;
    onChange: (url: string) => void;
    onClear: () => void;
    allowedFileTypes?: string[];
}

export const DragDropImageUploader = ({
    onUploadingStateChange,
    onChange,
    onClear,
    allowedFileTypes,
}: DragDropImageUploaderProps) => {
    const [selectedFileName, setSelectedFileName] = useState('');
    const [isSelectedFileValid, setIsSelectedFileValid] = useState(true);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const onSelectedFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.item(0);
        if (file) {
            uploadFile(file);
        }
    };

    const uploadFile = async (file: File) => {
        clearFileInput();
        setSelectedFileName(file.name);

        if (!ALLOWED_FILE_TYPES.includes(file.type)) {
            setIsSelectedFileValid(false);
            onClear();
            return;
        }

        setIsSelectedFileValid(true);
        onUploadingStateChange(true);

        try {
            const url = await uploadImageAndGetImageUrl(file);
            onChange(url);
        } catch (error) {
            console.error(error);
        } finally {
            onUploadingStateChange(false);
        }
    };

    const clearFileInput = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    const clearSelectedFile = () => {
        clearFileInput();
        setSelectedFileName('');
        setIsSelectedFileValid(true);
        onClear();
    };

    return (
        <Control spacing="compact">
            <input
                ref={inputRef}
                className={classes.input}
                type="file"
                onChange={onSelectedFileChange}
                accept={FILE_INPUT_ACCEPT}
            />

            <FileDropArea
                label={
                    <>
                        Drag your image here or{' '}
                        <Button variant="anchor" className="anchor-button" onClick={() => inputRef.current?.click()}>
                            select a file to upload
                        </Button>
                    </>
                }
                allowedFileTypes={allowedFileTypes ?? ALLOWED_FILE_TYPES}
                onFileDrop={uploadFile}
            />

            {selectedFileName && (
                <div className={classes.fileDetails}>
                    <div>
                        <span>{selectedFileName}</span>
                        {!isSelectedFileValid && <span>Unsupported format</span>}
                    </div>

                    <Button
                        color="danger"
                        variant="primary"
                        icon={<IconRemove />}
                        aria-label="Remove file"
                        onClick={clearSelectedFile}
                    />
                </div>
            )}
        </Control>
    );
};
