import { Spinner } from '@cimpress/react-components';
import { useContext, useState } from 'react';
import { ApiKeyContext } from '../App';
import { Content, Control, Page } from '../Components';
import {
    CardBuilder,
    ImageUrlSelector,
    LoadingContainer,
    PreviewRow,
    PreviewRows,
    PreviewSection,
} from '../Components/common';
import { useCancelableEffect } from '../tools';
import { useConvertRasterToVectorParams } from '../Components/ConvertRasterToVector/useConvertRasterToVector';
import { getConvertRasterToVector } from '../api';

export const ConvertRasterToVector = () => {
    const { imageUrl, setImageUrl } = useConvertRasterToVectorParams();
    const [resultRow, setResultRow] = useState(PreviewRow());
    const apiKey = useContext(ApiKeyContext);
    const [isLoading, setIsLoading] = useState(false);

    useCancelableEffect(
        (signal) => {
            (async () => {
                if (!imageUrl) {
                    return;
                }

                const originCard = CardBuilder.ofType('imageUrl')
                    .withTitle(`Raster image`)
                    .withData({
                        imageUrl,
                    })
                    .build();
                setResultRow(PreviewRow(originCard));

                setIsLoading(true);
                const resultImageUrl = await getConvertRasterToVector({ imageUrl, signal, apiKey });
                const resultCard = CardBuilder.ofType('imageUrl')
                    .withTitle(`Vector image`)
                    .withData({
                        imageUrl: resultImageUrl,
                    })
                    .build();
                setResultRow(PreviewRow(originCard, resultCard));
                setIsLoading(false);
            })();
        },
        [imageUrl, apiKey],
    );

    return (
        <Page>
            <Page.Title>Convert Raster to Vector</Page.Title>

            <Page.Controls>
                <LoadingContainer isLoading={isLoading}>
                    <Control>
                        <Control.Title>Image Url (allowed file types: .jpg, .png)</Control.Title>
                        <ImageUrlSelector
                            url={imageUrl ?? undefined}
                            onChange={(value) => setImageUrl(value ?? '')}
                            allowedFileTypes={['image/jpeg', 'image/png']}
                            // We're doing further processing after the URL is selected, so don't hide the loading overlay just yet
                            setIsImageLoading={(value) => (value ? setIsLoading(true) : undefined)}
                        />
                    </Control>
                </LoadingContainer>
            </Page.Controls>

            <Page.Content>
                {isLoading ? (
                    <Content.Card sticky size="large">
                        <Content.Section>
                            <Spinner />
                        </Content.Section>
                    </Content.Card>
                ) : (
                    <PreviewSection
                        introduction={
                            'This is a page to test the Convert Raster to Vector endpoint. Fill in the imageUrl to see converted results.'
                        }
                        showIntro={!imageUrl}
                        rows={PreviewRows(resultRow)}
                    />
                )}
            </Page.Content>
        </Page>
    );
};
