import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { NONE_SELECTOR_OPTION } from '../../constants';
import contentAlignmentOptions from '../../data/contentAlignmentOptions.json';
import type { SelectorOption } from '../common';

const DEFAULT_IMAGE_URL =
    'https://uploads.documents.cimpress.io/v1/uploads/46fa94b1-189c-4c30-9b00-3d02bc261f12~122/original/?tenant=vbu-cl&vpid=11507552';

const DEFAULT_SURFACE_JSON = {
    mcpSku: 'CIM-C7J4ZTK1-surfacetemplate',
    surfaceGroups: [
        {
            id: '57ea6e32-fa65-424a-9043-5b94547265e5',
            surfaces: [
                {
                    id: '9504aa9c-8b94-4d38-874c-b0b1db8a0eb5',
                    name: 'Front',
                    processType: 'directToGarment',
                    colorType: 'color',
                    widthCm: 30.48,
                    heightCm: 30.48,
                    location: {
                        name: 'Front',
                    },
                    docAdditionalData: {
                        masks: [
                            {
                                pathType: 'BLEED',
                                paths: [
                                    {
                                        anchorX: 0,
                                        anchorY: 0,
                                        pathPoints: [
                                            {
                                                ordinal: 1,
                                                endPointX: 30.48,
                                                endPointY: 0,
                                            },
                                            {
                                                ordinal: 2,
                                                endPointX: 30.48,
                                                endPointY: 30.48,
                                            },
                                            {
                                                ordinal: 3,
                                                endPointX: 0,
                                                endPointY: 30.48,
                                            },
                                        ],
                                    },
                                ],
                                boundingArea: {
                                    type: 'Rectangle',
                                    position: {
                                        x: 0,
                                        y: 0,
                                        width: 30.48,
                                        height: 30.48,
                                    },
                                },
                            },
                            {
                                pathType: 'TRIM',
                                paths: [
                                    {
                                        anchorX: 0,
                                        anchorY: 0,
                                        pathPoints: [
                                            {
                                                ordinal: 1,
                                                endPointX: 30.48,
                                                endPointY: 0,
                                            },
                                            {
                                                ordinal: 2,
                                                endPointX: 30.48,
                                                endPointY: 30.48,
                                            },
                                            {
                                                ordinal: 3,
                                                endPointX: 0,
                                                endPointY: 30.48,
                                            },
                                        ],
                                    },
                                ],
                                boundingArea: {
                                    type: 'Rectangle',
                                    position: {
                                        x: 0,
                                        y: 0,
                                        width: 30.48,
                                        height: 30.48,
                                    },
                                },
                            },
                        ],
                        guides: [],
                    },
                },
                {
                    id: '5b696fc2-fbcc-469f-b28f-f1a08471635d',
                    name: 'Back',
                    processType: 'directToGarment',
                    colorType: 'notApplicable',
                    widthCm: 30.48,
                    heightCm: 30.48,
                    location: {
                        name: 'Back',
                    },
                    docAdditionalData: {
                        masks: [
                            {
                                pathType: 'BLEED',
                                paths: [
                                    {
                                        anchorX: 0,
                                        anchorY: 0,
                                        pathPoints: [
                                            {
                                                ordinal: 1,
                                                endPointX: 30.48,
                                                endPointY: 0,
                                            },
                                            {
                                                ordinal: 2,
                                                endPointX: 30.48,
                                                endPointY: 30.48,
                                            },
                                            {
                                                ordinal: 3,
                                                endPointX: 0,
                                                endPointY: 30.48,
                                            },
                                        ],
                                    },
                                ],
                                boundingArea: {
                                    type: 'Rectangle',
                                    position: {
                                        x: 0,
                                        y: 0,
                                        width: 30.48,
                                        height: 30.48,
                                    },
                                },
                            },
                            {
                                pathType: 'TRIM',
                                paths: [
                                    {
                                        anchorX: 0,
                                        anchorY: 0,
                                        pathPoints: [
                                            {
                                                ordinal: 1,
                                                endPointX: 30.48,
                                                endPointY: 0,
                                            },
                                            {
                                                ordinal: 2,
                                                endPointX: 30.48,
                                                endPointY: 30.48,
                                            },
                                            {
                                                ordinal: 3,
                                                endPointX: 0,
                                                endPointY: 30.48,
                                            },
                                        ],
                                    },
                                ],
                                boundingArea: {
                                    type: 'Rectangle',
                                    position: {
                                        x: 0,
                                        y: 0,
                                        width: 30.48,
                                        height: 30.48,
                                    },
                                },
                            },
                        ],
                        guides: [],
                    },
                },
            ],
            prepressParametersUrl: '',
        },
    ],
};

const DEFAULT_CONTENT_ALIGNMENT = contentAlignmentOptions[0];

interface UseInspirationGenerateParams {
    themeQuery: string | null;
    imageUrl: string | null;
    primaryText: string | null;
    secondaryText: string | null;
    imageAsset: string | null;
    surfaceSpecificationUrl: string | null;
    surfaceSpecification: any;
    substrateColor: string | null;
    colorPaletteSelectedOption: SelectorOption;
    textOnly: boolean;
    iconCollection: string | null;
    contentAlignment: SelectorOption;
    setThemeQuery: (themeQuery: string) => void;
    setImageUrl: (imageUrl: string) => void;
    setPrimaryText: (primaryText: string) => void;
    setSecondaryText: (secondaryText: string) => void;
    setImageAsset: (imageAsset: string) => void;
    setSurfaceSpecificationUrl: (url: string) => void;
    setSurfaceSpecification: (surfaceSpec: any) => void;
    setSubstrateColor: (substrateColor: string) => void;
    setColorPaletteSelectedOption: (colorPaletteOption: SelectorOption) => void;
    setTextOnly: (textOnly: boolean) => void;
    setContentAlignment: (contentAlignmentOption: SelectorOption) => void;
}

export const useInspirationGenerateParams = (): UseInspirationGenerateParams => {
    const [searchParams, setSearchParams] = useSearchParams();

    const themeQueryParam = searchParams.get('themeQuery');
    const imageUrlParam = searchParams.get('imageUrl');
    const primaryTextParam = searchParams.get('primaryText');
    const secondaryTextParam = searchParams.get('secondaryText');
    const imageAssetParam = searchParams.get('imageAsset');
    const surfaceSpecificationParam = searchParams.get('surfaceSpecification');
    const surfaceSpecificationUrlParam = searchParams.get('surfaceSpecificationUrl');
    const substrateColorParam = searchParams.get('substrateColor');
    const colorPaletteParam = searchParams.get('colorPaletteSelectedOption');
    const textOnlyParam = searchParams.has('textOnly') ? searchParams.get('textOnly') === 'true' : false;
    const iconCollection = searchParams.get('iconCollection');
    const contentAlignmentParam = searchParams.get('contentAlignment');

    const [themeQuery, setThemeQuery] = useState(themeQueryParam);
    const [imageUrl, setImageUrl] = useState(imageUrlParam ?? DEFAULT_IMAGE_URL);
    const [primaryText, setPrimaryText] = useState(primaryTextParam);
    const [secondaryText, setSecondaryText] = useState(secondaryTextParam);
    const [imageAsset, setImageAsset] = useState(imageAssetParam);
    const [surfaceSpecificationUrl, setSurfaceSpecificationUrl] = useState(surfaceSpecificationUrlParam);
    const [surfaceSpecification, setSurfaceSpecification] = useState(
        surfaceSpecificationParam ? JSON.parse(surfaceSpecificationParam) : DEFAULT_SURFACE_JSON,
    );
    const [substrateColor, setSubstrateColor] = useState(substrateColorParam);
    const [colorPaletteSelectedOption, setColorPaletteSelectedOption] = useState(
        colorPaletteParam ? { label: 'Custom', value: colorPaletteParam } : NONE_SELECTOR_OPTION,
    );
    const [textOnly, setTextOnly] = useState(textOnlyParam);

    const [contentAlignment, setContentAlignment] = useState<SelectorOption>(
        contentAlignmentParam
            ? { label: contentAlignmentParam, value: contentAlignmentParam }
            : DEFAULT_CONTENT_ALIGNMENT,
    );

    useEffect(() => {
        const searchParamsObj: Record<string, string | string[]> = {};

        if (themeQuery) {
            searchParamsObj['themeQuery'] = themeQuery;
        }

        if (imageUrl) {
            searchParamsObj['imageUrl'] = imageUrl;
        }

        if (primaryText) {
            searchParamsObj['primaryText'] = primaryText;
        }

        if (secondaryText) {
            searchParamsObj['secondaryText'] = secondaryText;
        }

        if (imageAsset) {
            searchParamsObj['imageAsset'] = imageAsset;
        }

        if (surfaceSpecificationUrl) {
            searchParamsObj['surfaceSpecificationUrl'] = surfaceSpecificationUrl;
        }

        if (surfaceSpecification) {
            searchParamsObj['surfaceSpecification'] = JSON.stringify(surfaceSpecification);
        }

        if (substrateColor) {
            searchParamsObj['substrateColor'] = substrateColor;
        }

        if (textOnly) {
            searchParamsObj['textOnly'] = `${textOnly}`;
        }

        if (colorPaletteSelectedOption.value) {
            searchParamsObj['colorPaletteSelectedOption'] = colorPaletteSelectedOption.value;
        }

        if (iconCollection) {
            searchParamsObj['iconCollection'] = iconCollection;
        }

        if (contentAlignment && contentAlignment.value) {
            searchParamsObj['contentAlignment'] = contentAlignment.value;
        }

        setSearchParams(searchParamsObj);
    }, [
        themeQuery,
        imageUrl,
        primaryText,
        secondaryText,
        imageAsset,
        colorPaletteSelectedOption,
        surfaceSpecificationUrl,
        surfaceSpecification,
        substrateColor,
        textOnly,
        setSearchParams,
        iconCollection,
        contentAlignment,
    ]);

    return {
        themeQuery,
        imageUrl,
        primaryText,
        secondaryText,
        imageAsset,
        surfaceSpecificationUrl,
        surfaceSpecification,
        substrateColor,
        colorPaletteSelectedOption,
        textOnly,
        contentAlignment,
        iconCollection,
        setThemeQuery,
        setImageUrl,
        setPrimaryText,
        setSecondaryText,
        setImageAsset,
        setSurfaceSpecificationUrl,
        setSurfaceSpecification,
        setSubstrateColor,
        setColorPaletteSelectedOption,
        setTextOnly,
        setContentAlignment,
    };
};
