const local = {
    // These env variables can be set in your .env.local file to override the default values
    backendServiceUrl: import.meta.env.VITE_AGS_URL || 'https://artworkgeneration.cimpress.io',
    playgroundApiUrl: import.meta.env.VITE_PLAYGROUND_API_URL || 'https://api.playground.artworkgeneration.cimpress.io',
    samplesS3BaseUrl: import.meta.env.VITE_SAMPLES_S3_BASE_URL || 'https://da4frmzgpkwrv.cloudfront.net',
};

const dev = {
    backendServiceUrl: 'https://dev.artworkgeneration.cimpress.io',
    playgroundApiUrl: 'https://dev.api.playground.artworkgeneration.cimpress.io',
    samplesS3BaseUrl: 'https://d3ql8jlymk2sl0.cloudfront.net',
};

const prod = {
    backendServiceUrl: 'https://artworkgeneration.cimpress.io',
    playgroundApiUrl: 'https://api.playground.artworkgeneration.cimpress.io',
    samplesS3BaseUrl: 'https://da4frmzgpkwrv.cloudfront.net',
};

const config = import.meta.env.MODE === 'production' ? prod : import.meta.env.MODE === 'development' ? dev : local;

const exportedConfig = {
    ...config,
};

export default exportedConfig;
