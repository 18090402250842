import { Select, TextField } from '@cimpress/react-components';
import { useEffect, useState } from 'react';
import { Control, Page, SurfaceSelector } from '../Components';
import {
    ResizeDimensionsControl,
    ResizePreview,
    ResizeSourceControl,
    useResizeParams,
    type SurfaceData,
} from '../Components/Resize';

const intro = `This is the page to test our resize endpoint.\nFill in or select a document url to get started.`;

const priorities = [
    { value: 'speed', label: 'Speed' },
    { value: 'quality', label: 'Quality' },
];

export const ResizePage = () => {
    const resizeParams = useResizeParams();
    const [surfacesData, setSurfacesData] = useState<SurfaceData[]>([
        {
            title: 'Product Selector',
            surfaceUrl: resizeParams.surfaceSpecificationUrl,
        },
    ]);
    const [sourceSurfacesData, setSourcesSurfaceData] = useState<SurfaceData[]>([
        {
            title: 'Product Selector',
            surfaceUrl: resizeParams.sourceSurfaceSpecificationUrl,
        },
    ]);
    const [resizePriority, setResizePriority] = useState(resizeParams.priority ?? 'speed');
    useEffect(() => {
        setResizePriority(resizeParams.priority ?? 'speed');
    }, [resizeParams, resizeParams.priority]);

    useEffect(() => {
        setSurfacesData([
            {
                title: 'Product Selector',
                surfaceUrl: resizeParams.surfaceSpecificationUrl,
            },
        ]);
    }, [resizeParams.surfaceSpecificationUrl]);

    useEffect(() => {
        setSourcesSurfaceData([
            {
                title: 'Product Selector',
                surfaceUrl: resizeParams.sourceSurfaceSpecificationUrl,
            },
        ]);
    }, [resizeParams.sourceSurfaceSpecificationUrl]);

    const setDocumentUrl = (docUrl: string) => {
        resizeParams.setDocument(docUrl);
    };

    return (
        <Page>
            <Page.Title>Resize</Page.Title>

            <Page.Controls>
                <ResizeSourceControl document={resizeParams.document} setDocument={setDocumentUrl} />
                <Control>
                    <Control.Title>Minimum Font Size</Control.Title>
                    <TextField
                        label="Minimum Font Size (e.g. 8pt)"
                        value={resizeParams.minimumFontSize}
                        onChange={(e) => resizeParams.setMinimumFontSize(e.target.value)}
                    />
                </Control>

                <Control>
                    <Control.Title>Priority</Control.Title>
                    <Select
                        onChange={(selected) => {
                            const newPriority = selected.value;
                            setResizePriority(newPriority);
                            resizeParams.setPriority(newPriority);
                        }}
                        value={priorities.find((option) => option.value === resizePriority) || priorities[0]}
                        options={priorities}
                        label="Priority"
                    />
                </Control>

                <Control>
                    <Control.Title>Surface Selection</Control.Title>
                    <SurfaceSelector
                        surfaceSpecificationUrl={resizeParams.surfaceSpecificationUrl}
                        setSurfaceSpecificationUrl={resizeParams.setSurfaceSpecificationUrl}
                        surfaceSpecification={resizeParams.surfaceSpecification}
                        setSurfaceSpecification={resizeParams.setSurfaceSpecification}
                    />
                </Control>
                <Control>
                    <Control.Title>Source Surface Selection</Control.Title>
                    <SurfaceSelector
                        surfaceSpecificationUrl={resizeParams.sourceSurfaceSpecificationUrl}
                        setSurfaceSpecificationUrl={resizeParams.setSourceSurfaceSpecificationUrl}
                        surfaceSpecification={resizeParams.sourceSurfaceSpecification}
                        setSurfaceSpecification={resizeParams.setSourceSurfaceSpecification}
                    />
                </Control>
                <ResizeDimensionsControl
                    physicalities={resizeParams.physicalities}
                    customPhysicality={resizeParams.customPhysicality}
                    setCustomPhysicality={resizeParams.setCustomPhysicality}
                    setPhysicalities={resizeParams.setPhysicalities}
                />
            </Page.Controls>

            <Page.Content>
                <ResizePreview
                    documentUrl={resizeParams.document}
                    physicalities={resizeParams.physicalities}
                    customPhysicality={resizeParams.customPhysicality}
                    surfacesData={surfacesData}
                    surfaceSpecification={resizeParams.surfaceSpecification}
                    debug={resizeParams.debug}
                    minimumFontSize={resizeParams.minimumFontSize}
                    intro={intro}
                    priority={resizeParams.priority}
                    sourceSurfacesData={sourceSurfacesData}
                    sourceSurfaceSpecification={resizeParams.sourceSurfaceSpecification}
                />
            </Page.Content>
        </Page>
    );
};
