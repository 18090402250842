import type { CimpressDocument } from '@mcp-artwork/cimdoc-types-v2';
import axios from 'axios';
import config from '../config';
import { getAgsDebugInfoHeaderFromResponse, type ExperimentalDebugInfo, type TextClassifyResult } from './callService';

const host = config.backendServiceUrl;

export type DeductiveDesignContextMessage =
    | {
          role: 'user';
          content: string;
          shouldNotBeTagged: boolean;
      }
    | {
          role: 'assistant';
          content: {
              freeTextDecoderResult: TextClassifyResult[];
              textClassifyTagsResult: TextClassifyResult[];
          };
          shouldNotBeTagged: boolean;
      };

export const getDeductiveDesign = async ({
    input,
    targetProductName,
    apiKey,
}: {
    input: DeductiveDesignContextMessage[];
    targetProductName?: string;
    apiKey: string;
}): Promise<{
    debugInfo: NonNullable<ExperimentalDebugInfo['generateDeductiveDesign']>;
    documents: CimpressDocument[];
}> => {
    const basePath = '/api/v0/inspiration:generateDeductiveDesign';

    const body = JSON.stringify({
        input,
        targetProductName,
        apiKey,
    });

    try {
        const response = await axios.post<CimpressDocument[]>(`${host}${basePath}`, body, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            validateStatus: (status) => (status >= 200 && status < 300) || status === 422,
        });

        const deductiveDesignDebugInfo =
            getAgsDebugInfoHeaderFromResponse(response)?.experimental?.generateDeductiveDesign;

        if (!deductiveDesignDebugInfo) {
            throw new Error('No deductive design metadata found');
        }

        return { debugInfo: deductiveDesignDebugInfo, documents: response.data };
    } catch (error) {
        console.error(`An error occurred while generating deductive design: ${error}`);
        throw error;
    }
};
