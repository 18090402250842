import { useState } from 'react';
import { useDebouncedEffect } from '../../tools';
import { ControlTitleToggle } from '../common';
import { Control } from '../layout';
import { type ColorPalette, ColorPaletteEditor } from './colors';
import { type FontSchema, FontSchemaEditor } from './fonts';
import { type ImageAsset, ImageListEditor } from './images';
import { type TextAsset, TextListEditor } from './texts';
import type { ComposeAssets } from './assets';

export interface ComposeControlsProps {
    initialComponents?: ComposeAssets;
    setComponents: (components?: ComposeAssets) => void;
}

export const ComposeControls = ({ initialComponents, setComponents }: ComposeControlsProps) => {
    const initialTexts = initialComponents?.texts;
    const initialImages = initialComponents?.images;

    const initialFontSchema = initialComponents?.fontSchema;
    const initialColorPalette = initialComponents?.colorPalette;

    const [texts, setTexts] = useState<TextAsset[] | undefined>(initialTexts);
    const [images, setImages] = useState<ImageAsset[] | undefined>(initialImages);

    const [fontSchema, setFontSchema] = useState<FontSchema | undefined>(initialFontSchema);
    const [isFontSchemaRequired, setIsFontSchemaRequired] = useState(!!initialFontSchema);

    const [colorPalette, setColorPalette] = useState<ColorPalette | undefined>(initialColorPalette);
    const [isColorPaletteRequired, setIsColorPaletteRequired] = useState(!!initialColorPalette);

    useDebouncedEffect(
        () => {
            if (!(texts && texts.length) && !(images && images.length) && !fontSchema && !colorPalette) {
                // No content
                setComponents(undefined);
                return;
            }

            if (texts && images && (!isFontSchemaRequired || fontSchema) && (!isColorPaletteRequired || colorPalette)) {
                setComponents({
                    texts: texts.length ? texts : undefined,
                    fontSchema: fontSchema ? fontSchema : undefined,
                    colorPalette: colorPalette ? colorPalette : undefined,
                    images,
                });
            } else {
                setComponents(undefined);
            }
        },
        [texts, images, isFontSchemaRequired, fontSchema, isColorPaletteRequired, colorPalette, setComponents],
        500,
    );

    return (
        <>
            <Control>
                <Control.Title>Texts</Control.Title>
                <TextListEditor initialValue={texts} onChange={setTexts} />
            </Control>

            <Control>
                <Control.Title>Images</Control.Title>
                <ImageListEditor initialValue={images} onChange={setImages} />
            </Control>

            <Control>
                <ControlTitleToggle
                    title="Font Schema"
                    isOn={isFontSchemaRequired}
                    onToggle={(isOn) => {
                        if (isOn) {
                            setIsFontSchemaRequired(true);
                        } else {
                            setIsFontSchemaRequired(false);
                            setFontSchema(undefined);
                        }
                    }}
                />

                {isFontSchemaRequired && <FontSchemaEditor initialValue={fontSchema} onChange={setFontSchema} />}
            </Control>

            <Control>
                <ControlTitleToggle
                    title="Color Palette"
                    isOn={isColorPaletteRequired}
                    onToggle={(isOn) => {
                        if (isOn) {
                            setIsColorPaletteRequired(true);
                        } else {
                            setIsColorPaletteRequired(false);
                            setColorPalette(undefined);
                        }
                    }}
                />

                {isColorPaletteRequired && (
                    <ColorPaletteEditor initialValue={colorPalette} onChange={setColorPalette} />
                )}
            </Control>
        </>
    );
};
