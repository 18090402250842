import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import type { SelectorOption } from '../common';

interface UseCompositeParams {
    targetDocumentUrl: string;
    components: any;
    priority: SelectorOption;
    surfaceSpecificationUrl: string;
    surfaceSpecification: any;
    applyOnlyToPlaceholders: boolean;
    isBetaEndpoint: boolean;
    setTargetDocumentUrl: (url: string) => void;
    setComponents: (components: any) => void;
    setPriority: (priority: SelectorOption) => void;
    setSurfaceSpecificationUrl: (url: string) => void;
    setSurfaceSpecification: (surface: any) => void;
    setApplyOnlyToPlaceholders: (applyOnlyToPlaceholders: boolean) => void;
    setIsBetaEndpoint: (isBetaEndpoint: boolean) => void;
}

export const PRIORITY_OPTIONS = [
    { label: 'speed', value: 'speed' },
    { label: 'quality', value: 'quality' },
];

export const useCompositeParams = (): UseCompositeParams => {
    const [searchParams, setSearchParams] = useSearchParams();

    const documentUrl = searchParams.get('targetDocumentUrl');
    const componentsParam = searchParams.get('components');
    const priorityParam = searchParams.get('priority') || 'speed';
    const surfaceSpecificationParam = searchParams.get('surfaceSpecification');
    const surfaceSpecificationUrlParam = searchParams.get('surfaceSpecificationUrl');
    const applyOnlyToPlaceholdersParam = searchParams.get('applyOnlyToPlaceholders');
    const isBetaEndpointParam = searchParams.get('isBetaEndpoint');

    const [targetDocumentUrl, setTargetDocumentUrl] = useState(documentUrl || '');
    const [components, setComponents] = useState(componentsParam ? JSON.parse(componentsParam) : undefined);
    const [priority, setPriority] = useState<SelectorOption>(
        priorityParam ? { label: priorityParam, value: priorityParam } : PRIORITY_OPTIONS[0],
    );
    const [surfaceSpecificationUrl, setSurfaceSpecificationUrl] = useState(surfaceSpecificationUrlParam || '');
    const [surfaceSpecification, setSurfaceSpecification] = useState(
        surfaceSpecificationParam ? JSON.parse(surfaceSpecificationParam) : undefined,
    );
    const [applyOnlyToPlaceholders, setApplyOnlyToPlaceholders] = useState(!!applyOnlyToPlaceholdersParam || false);
    const [isBetaEndpoint, setIsBetaEndpoint] = useState(!!isBetaEndpointParam || false);
    useEffect(() => {
        const searchParamsObj: Record<string, string> = {};

        if (targetDocumentUrl) {
            searchParamsObj['targetDocumentUrl'] = targetDocumentUrl;
        }

        if (components) {
            searchParamsObj['components'] = JSON.stringify(components);
        }

        if (priority) {
            searchParamsObj['priority'] = priority.value;
        }

        if (surfaceSpecificationUrl) {
            searchParamsObj['surfaceSpecificationUrl'] = surfaceSpecificationUrl;
        }

        if (surfaceSpecification) {
            searchParamsObj['surfaceSpecification'] = JSON.stringify(surfaceSpecification);
        }

        if (applyOnlyToPlaceholders) {
            searchParamsObj['applyOnlyToPlaceholders'] = applyOnlyToPlaceholders.toString();
        }

        if (isBetaEndpoint) {
            searchParamsObj['isBetaEndpoint'] = isBetaEndpoint.toString();
        }

        setSearchParams(searchParamsObj);
    }, [
        targetDocumentUrl,
        components,
        priority,
        setSearchParams,
        surfaceSpecificationUrl,
        surfaceSpecification,
        applyOnlyToPlaceholders,
        isBetaEndpoint,
    ]);

    return {
        targetDocumentUrl,
        components,
        priority,
        surfaceSpecificationUrl,
        surfaceSpecification,
        applyOnlyToPlaceholders,
        isBetaEndpoint,
        setTargetDocumentUrl,
        setComponents,
        setPriority,
        setSurfaceSpecificationUrl,
        setSurfaceSpecification,
        setApplyOnlyToPlaceholders,
        setIsBetaEndpoint,
    };
};
