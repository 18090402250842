import { useContext, useEffect, useState } from 'react';
import {
    getPhotoSceneUrl,
    getResizeByDimensionsUrl,
    getResizeDocumentWithSurfaceJson,
    getResizeDocumentWithSurfaceUrl,
} from '../../api';
import { ApiKeyContext } from '../../App';
import { getUrl } from '../../tools';
import { type Card, CardBuilder, PreviewRow, PreviewRows, PreviewSection } from '../common';
import type { Physicality, SurfaceData } from './types';

interface ResizePreviewProps {
    documentUrl: string;
    physicalities?: Physicality[];
    surfacesData: SurfaceData[];
    customPhysicality?: Physicality;
    debug: boolean;
    minimumFontSize?: string;
    intro: string;
    surfaceSpecification?: any;
    version?: string;
    priority?: string;
    sourceSurfacesData?: SurfaceData[];
    sourceSurfaceSpecification?: any;
}

export const ResizePreview = ({
    documentUrl,
    physicalities,
    surfacesData,
    customPhysicality,
    debug,
    minimumFontSize,
    intro,
    surfaceSpecification,
    priority,
    sourceSurfacesData,
    sourceSurfaceSpecification,
}: ResizePreviewProps) => {
    const [sourceRow, setSourceRow] = useState<Card[]>([]);
    const [resizeForSurfaceCards, setResizeForSurfaceCards] = useState<Card[]>([]);
    const [physicalityCards, setPhysicalityCards] = useState<Card[]>([]);
    const [customPhysicalityCards, setCustomPhysicalityCards] = useState<Card[]>([]);
    const apiKey = useContext(ApiKeyContext);

    useEffect(() => {
        const sourceCard = CardBuilder.ofType('documentUrl')
            .withTitle('Source preview')
            .withData({ documentUrl })
            .build();

        setSourceRow(PreviewRow(sourceCard));
    }, [documentUrl]);

    useEffect(() => {
        const uri = getUrl(documentUrl);

        if (!uri || (!surfaceSpecification && (!surfacesData?.length || !surfacesData[0].surfaceUrl))) {
            setResizeForSurfaceCards([]);
            return;
        }

        const surfaceData = surfacesData?.find((data) => data.surfaceUrl);
        if (surfaceSpecification) {
            const physicalityUrl = getResizeDocumentWithSurfaceJson(
                uri,
                surfaceSpecification,
                minimumFontSize,
                apiKey,
                priority,
                sourceSurfaceSpecification,
            );
            const newSurfaceSpecificationCard = CardBuilder.ofType('documentUrl')
                .withTitle('Result Document')
                .withData({ documentUrl: physicalityUrl ?? '', backgroundColor: 'FFFFFF' })
                .build();

            setResizeForSurfaceCards([newSurfaceSpecificationCard]);
        } else if (surfaceData) {
            const sourceSurfaceData = sourceSurfacesData?.find((data) => data.surfaceUrl);
            const resizeUrl = getResizeDocumentWithSurfaceUrl(
                uri,
                surfaceData.surfaceUrl,
                minimumFontSize,
                apiKey,
                priority,
                sourceSurfaceData?.surfaceUrl,
            );

            const newResizeForSurfaceCards = CardBuilder.ofType('documentUrl')
                .withTitle(surfaceData.title)
                .withLoader(async ({ signal }) => {
                    const photoSceneUrl = (await getPhotoSceneUrl(surfaceData.surfaceUrl, signal)) ?? '';
                    return {
                        documentUrl: resizeUrl,
                        photoSceneUrl,
                        surfaceUrl: surfaceData.surfaceUrl,
                        backgroundColor: 'FFFFFF',
                        debug,
                    };
                })
                .build();

            setResizeForSurfaceCards([newResizeForSurfaceCards]);
        }
    }, [
        documentUrl,
        minimumFontSize,
        surfaceSpecification,
        sourceSurfaceSpecification,
        debug,
        apiKey,
        setResizeForSurfaceCards,
        priority,
        surfacesData,
        sourceSurfacesData,
    ]);

    useEffect(() => {
        const uri = getUrl(documentUrl);
        if (!uri || !customPhysicality) {
            setCustomPhysicalityCards([]);
            return;
        }

        const customPhysicalityUrl = getResizeByDimensionsUrl(
            uri,
            customPhysicality.width,
            customPhysicality.height,
            minimumFontSize,
            apiKey,
            priority,
        );

        const newCustomPhysicalityCard = CardBuilder.ofType('documentUrl')
            .withTitle('Custom')
            .withData({ documentUrl: customPhysicalityUrl ?? '', backgroundColor: 'FFFFFF' })
            .build();

        setCustomPhysicalityCards([newCustomPhysicalityCard]);
    }, [documentUrl, customPhysicality, minimumFontSize, setCustomPhysicalityCards, apiKey, priority]);

    useEffect(() => {
        const uri = getUrl(documentUrl);
        if (!uri || !physicalities?.length) {
            setPhysicalityCards([]);
            return;
        }

        const newPhysicalityCards: Card[] = physicalities.map((p) => {
            const physicalityUrl = getResizeByDimensionsUrl(uri, p.width, p.height, minimumFontSize, apiKey, priority);
            return CardBuilder.ofType('documentUrl')
                .withTitle(p.name)
                .withData({ documentUrl: physicalityUrl ?? '', backgroundColor: 'FFFFFF' })
                .build();
        });

        setPhysicalityCards(newPhysicalityCards);
    }, [documentUrl, physicalities, minimumFontSize, setPhysicalityCards, apiKey, priority]);

    const resultRow = PreviewRow(...resizeForSurfaceCards, ...customPhysicalityCards, ...physicalityCards);

    return <PreviewSection introduction={intro} showIntro={!documentUrl} rows={PreviewRows(sourceRow, resultRow)} />;
};
