import { Button } from '@cimpress/react-components';
import { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isStringEmptyOrWhitespace, useUpdateEffect } from '../../../tools';
import { Control } from '../../layout';
import type { ImageAsset } from './types';
import { ImageAssetEditor, LENGTH_WITHOUT_UNIT_REGEX } from './ImageAssetEditor';

export interface ImageListEditorProps {
    initialValue?: ImageAsset[];
    onChange: (value?: ImageAsset[]) => void;
}

interface ImageListItem {
    id: string;
    asset?: ImageAsset;
}

export const ImageListEditor = ({ initialValue, onChange }: ImageListEditorProps) => {
    const [imageList, setImageList] = useState<ImageListItem[]>(() =>
        (initialValue ?? []).map((asset) => ({ id: uuidv4(), asset })),
    );

    const addImageAsset = () => {
        setImageList((prev) => [...prev, { id: uuidv4() }]);
    };

    const removeImageAsset = useCallback((id: string) => {
        setImageList((prev) => prev.filter((item) => item.id !== id));
    }, []);

    const updateImageAsset = useCallback((id: string, asset: ImageAsset) => {
        setImageList((prev) => {
            const existingAsset = prev.find((a) => a.id === id)!;

            // This is technically "illegal" in React as we should avoid mutating the state,
            // but this whole component doesn't really care about the wrapper object reference.
            // The state update will be picked up by React anyway because we're returning a new array below.
            existingAsset.asset = asset;

            return [...prev];
        });
    }, []);

    useUpdateEffect(() => {
        if (
            imageList.every(
                ({ asset }) =>
                    !isStringEmptyOrWhitespace(asset?.printUrl) &&
                    !isStringEmptyOrWhitespace(asset?.previewUrl) &&
                    asset?.height &&
                    asset.width,
            )
        ) {
            onChange(
                imageList.map((item) => {
                    const height = item.asset!.height;
                    const width = item.asset!.width;
                    if (typeof height === 'number') {
                        item.asset!.height = height;
                    } else if (typeof height === 'string' && height.match(LENGTH_WITHOUT_UNIT_REGEX)) {
                        item.asset!.height = parseFloat(height);
                    }

                    if (typeof width === 'number') {
                        item.asset!.width = width;
                    } else if (typeof width === 'string' && width.match(LENGTH_WITHOUT_UNIT_REGEX)) {
                        item.asset!.width = parseFloat(width);
                    }

                    return item.asset!;
                }),
            );
        } else {
            onChange(undefined);
        }
    }, [imageList, onChange]);

    return (
        <Control>
            {imageList.map(({ id, asset }) => (
                <ImageAssetEditor
                    key={id}
                    assetId={id}
                    initialValue={asset}
                    onChange={updateImageAsset}
                    onRemove={removeImageAsset}
                />
            ))}

            <Button variant="primary" blockLevel onClick={addImageAsset}>
                Add new image
            </Button>
        </Control>
    );
};
