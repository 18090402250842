export const PRODUCT_TO_SURFACE_MAP = [
    {
        products: ['giftcertificates'],
        url: 'https://surfaces.products.vpsvc.com/surfaces/PRD-EDHM9OOU/15?optionSelections%5BBackside%5D=Blank&optionSelections%5BStock%5D=Matte&requestor=studio',
        sku: 'CIM-ZG3BZW8Q',
        skuVariable: {
            Backside: 'Blank',
            Stock: 'Matte',
        },
    },
    {
        products: ['postcards'],
        url: 'https://surfaces.products.vpsvc.com/surfaces/PRD-ENRS4XZIS/32?optionSelections%5BAddress%20Service%20Option%5D=Not%20Applicable&optionSelections%5BBackside%5D=Blank&optionSelections%5BCorners%5D=Standard&optionSelections%5BMailing%20Services%5D=Not%20Mailable&optionSelections%5BPaper%20Finish%5D=None&optionSelections%5BPaper%20Stock%5D=Glossy%20%28front%20only%29&optionSelections%5BPaper%20Thickness%5D=Standard&optionSelections%5BPostage%5D=Not%20Applicable&optionSelections%5BProcessing%5D=Not%20Applicable&optionSelections%5BProduct%20Orientation%5D=Horizontal&optionSelections%5BSize%5D=4.2%22%20x%205.5%22&requestor=studio',
        sku: 'CIM-FQRCPMKN',
        skuVariable: {
            'Address Service Option': 'Not Applicable',
            Backside: 'Blank',
            Corners: 'Standard',
            'Mailing Services': 'Not Mailable',
            'Paper Finish': 'None',
            'Paper Stock': 'Glossy (front only)',
            'Paper Thickness': 'Standard',
            Postage: 'Not Applicable',
            Processing: 'Not Applicable',
            'Product Orientation': 'Horizontal',
            Size: '4.2" x 5.5"',
        },
    },
    {
        products: ['mugs'],
        url: 'https://design-specifications.design.vpsvc.com/v2/designViews/products/PRD-0BKUQJYW/21?requester=studio&optionSelections%5BColor%5D=White&optionSelections%5BDesignable%20Area%5D=Wraparound&optionSelections%5BHandle%20Color%5D=%23ffffff&optionSelections%5BSize%5D=11oz&culture=en-us',
        sku: 'CIM-5F8R8ZQN',
        skuVariable: {
            Color: 'White',
            'Designable Area': 'Wraparound',
            'Handle Color': '#ffffff',
            Size: '11oz',
        },
    },
    {
        products: ['pens'],
        url: 'https://surfaces.products.vpsvc.com/surfaces/PRD-H1ZUN4GZ/11?optionSelections%5BEngraving%20height%5D=Standard&optionSelections%5BFinish%5D=Metallic&optionSelections%5BInk%20Color%5D=Black&optionSelections%5BSubstrate%20Color%5D=%2333629e&requestor=studio',
        sku: 'CIM-J523DA8G',
        skuVariable: {
            'Engraving height': 'Standard',
            Finish: 'Metallic',
            'Ink Color': 'Black',
            'Substrate Color': '#33629e',
        },
    },
    {
        products: ['businesscards'],
        url: 'https://surfaces.products.vpsvc.com/surfaces/PRD-IYXT1T3V/38?optionSelections%5BBacksides%5D=Blank&optionSelections%5BConnected%20Cards%5D=None&optionSelections%5BCorners%5D=Standard%20Corners&optionSelections%5BFinish%5D=None&optionSelections%5BProduct%20Orientation%5D=Horizontal&optionSelections%5BShape%5D=Standard&optionSelections%5BSubstrate%5D=Matte&optionSelections%5BThickness%5D=Standard&requestor=studio',
        sku: 'CIM-666QNK0Y',
        skuVariable: {
            Backsides: 'Blank',
            'Connected Cards': 'None',
            Corners: 'Standard Corners',
            Finish: 'None',
            'Product Orientation': 'Horizontal',
            Shape: 'Standard',
            Substrate: 'Matte',
            Thickness: 'Standard',
        },
        mpv: 'standardBusinessCards',
        productKey: 'PRD-IYXT1T3V',
    },
    {
        products: ['notepads'],
        url: 'https://surfaces.products.vpsvc.com/surfaces/PRD-VSYISJHX/19?optionSelections%5BProduct%20Orientation%5D=Vertical&optionSelections%5Bbackside%5D=Cardboard&optionSelections%5Bsize%5D=4%22%20x%205.5%22&requestor=studio',
        sku: 'CIM-8878Q238',
        skuVariable: {
            'Product Orientation': 'Vertical',
            backside: 'Cardboard',
            size: '4" x 5.5"',
        },
    },
    {
        products: ['flyers'],
        url: 'https://surfaces.products.vpsvc.com/surfaces/PRD-F2EJ5DIT/42?optionSelections%5BBackside%5D=Blank&optionSelections%5BPaper%20Stock%5D=Uncoated&optionSelections%5BPaper%20Thickness%5D=Budget&optionSelections%5BPerforation%5D=No&optionSelections%5BProduct%20Orientation%5D=Horizontal&optionSelections%5BSize%5D=4%22%20x%206%22&requestor=studio',
        sku: 'CIM-F2E62RZW',
        skuVariable: {
            Backside: 'Blank',
            'Paper Stock': 'Uncoated',
            'Paper Thickness': 'Budget',
            Perforation: 'No',
            'Product Orientation': 'Horizontal',
            Size: '4" x 6"',
        },
    },
    {
        products: ['mousepads'],
        url: 'https://surfaces.products.vpsvc.com/surfaces/PRD-UNKWMBMF/14?optionSelections%5BSubstrate%20Color%5D=%23f3f3f5&requestor=studio',
        sku: 'CIM-92HG0KT2',
        skuVariable: {
            'Substrate Color': '#f3f3f5',
        },
        mpv: 'mousePads',
        productKey: 'PRD-XDLYJWSG',
    },
    {
        products: ['carmagnets'],
        url: 'https://surfaces.products.vpsvc.com/surfaces/PRD-EDT51BHH/25?optionSelections%5BCustom%20Height%5D=8.7&optionSelections%5BCustom%20Width%5D=11.5&optionSelections%5BShape%5D=Square%2FRectangle&optionSelections%5BSize%5D=Custom&requestor=studio',
        sku: 'CIM-HDTHKT1N',
        skuVariable: { Size: '8.7" x 11.5"', Shape: 'Square/Rectangle' },
        mpv: 'carDoorMagnets',
        productKey: 'PRD-EDT51BHH',
    },
    {
        products: ['tshirts'],
        url: 'https://surfaces.products.vpsvc.com/surfaces/PRD-IUUXABISF/38?optionSelections%5BBackside%5D=Blank&optionSelections%5BDeco%20Area%5D=Full%20Front&optionSelections%5BSize%5D=2XL&optionSelections%5BSubstrate%20Color%5D=%23000000&requestor=studio',
        sku: 'CIM-Y0KHG7FA',
        skuVariable: {
            Backside: 'Blank',
            'Deco Area': 'Full Front',
            Size: '2XL',
            'Substrate Color': '#000000',
        },
    },
    {
        products: ['sheetstickers'],
        url: 'https://surfaces.products.vpsvc.com/surfaces/PRD-ADZNM4LP/45?optionSelections%5BFinish%5D=Glossy&optionSelections%5BMaterial%5D=White%20Paper&optionSelections%5BProduct%20Orientation%5D=Horizontal&optionSelections%5BShape%5D=Circle&optionSelections%5BSize%5D=1.5%22x1.5%22&requestor=studio',
        sku: 'CIM-MK50GZPX',
        skuVariable: {
            Finish: 'Glossy',
            Material: 'White Paper',
            'Product Orientation': 'Horizontal',
            Shape: 'Circle',
            Size: '1.5"x1.5"',
        },
    },
    {
        products: ['envelopeseals'],
        url: 'https://surfaces.products.vpsvc.com/surfaces/PRD-8GPNVH4F/15?optionSelections%5BFinish%5D=Glossy&optionSelections%5BMaterial%5D=White%20Paper&optionSelections%5BProduct%20Orientation%5D=Horizontal&optionSelections%5BShape%5D=Circle&optionSelections%5BSize%5D=1.5%22x1.5%22&requestor=studio',
        sku: 'CIM-MK50GZPX',
        skuVariable: {
            Finish: 'Glossy',
            Material: 'White Paper',
            'Product Orientation': 'Horizontal',
            Shape: 'Circle',
            Size: '1.5"x1.5"',
        },
        mpv: 'envelopeSeals',
        productKey: 'PRD-8GPNVH4F',
    },
    {
        products: ['notebooks'],
        url: 'https://surfaces.products.vpsvc.com/surfaces/PRD-G3SQ16F8/24?optionSelections%5BBack%20Cover%5D=Blank&optionSelections%5BCover%20Stock%5D=Glossy&optionSelections%5BFiller%20Paper%5D=College%20Ruled&optionSelections%5BInside%20Back%20Cover%5D=Blank&optionSelections%5BInside%20Front%20Cover%5D=Blank&optionSelections%5BSize%5D=5.5%22%20x%208.5%22&optionSelections%5BStyle%5D=Notebook&requestor=studio',
        sku: 'CIM-2ACNR6Z3',
        skuVariable: {
            'Back Cover': 'Blank',
            'Cover Stock': 'Glossy',
            'Filler Paper': 'College Ruled',
            'Inside Back Cover': 'Blank',
            'Inside Front Cover': 'Blank',
            Size: '5.5" x 8.5"',
            Style: 'Notebook',
        },
        mpv: 'notebooks',
        productKey: 'PRD-G3SQ16F8',
    },
    {
        products: ['cardecals'],
        url: 'https://surfaces.products.vpsvc.com/surfaces/PRD-XROFY2WA/16?optionSelections%5BCustom%20Height%5D=5&optionSelections%5BCustom%20Width%5D=5&optionSelections%5BMaterial%5D=Opaque&optionSelections%5BOrientation%5D=Horizontal&optionSelections%5BShape%5D=Rectangle&optionSelections%5BSize%5D=5%22%20x%205%22&optionSelections%5BWhite%20Layer%5D=False&requestor=studio',
        sku: 'CIM-UU1RDMH5',
        skuVariable: { Size: '5" x 5"', Material: 'Opaque', Shape: 'Rectangle', Orientation: 'Horizontal' },
        mpv: 'carDoorDecals',
        productKey: 'PRD-XROFY2WA',
    },
    {
        products: ['yardsigns'],
        url: 'https://surfaces.products.vpsvc.com/surfaces/PRD-R1AJEBVL/51?optionSelections%5BFinish%5D=None&optionSelections%5BGrommets%5D=No&optionSelections%5BOrientation%5D=Horizontal&optionSelections%5BShapes%5D=Rectangle&optionSelections%5BSingle%20vs%20Double%20Sided%20Printing%5D=Single%20Sided&optionSelections%5BSize%5D=18%22%20x%2024%22&requestor=studio',
        sku: 'CIM-JAKNA074',
        skuVariable: {
            Finish: 'None',
            Grommets: 'No',
            Orientation: 'Horizontal',
            Shapes: 'Rectangle',
            'Single vs Double Sided Printing': 'Single Sided',
            Size: '18" x 24"',
        },
    },
    {
        products: ['rolllabels'],
        url: 'https://surfaces.products.vpsvc.com/surfaces/PRD-DF5PWTHC/55?optionSelections%5BFinish%5D=Gloss&optionSelections%5BMaterial%5D=White%20Paper&optionSelections%5BProduct%20Orientation%5D=Horizontal&optionSelections%5BShape%5D=Circle&optionSelections%5BSize%5D=1%22x1%22&optionSelections%5BUnwind%20Direction%5D=Bottom%20of%20Copy&optionSelections%5BWhite%20Layer%5D=False&requestor=studio',
        sku: 'CIM-6KQ609QW',
        skuVariable: {
            Finish: 'Gloss',
            Material: 'White Paper',
            'Product Orientation': 'Horizontal',
            Shape: 'Circle',
            Size: '1"x1"',
            'Unwind Direction': 'Bottom of Copy',
            'White Layer': 'False',
        },
    },
    {
        products: ['paperbags'],
        url: 'https://surfaces.products.vpsvc.com/surfaces/PRD-U5XQ7S1K/4?optionSelections%5BBackside%5D=Blank&optionSelections%5BPaper%20Stock%5D=White%20kraft&optionSelections%5BSize%5D=Small%20%28%207.5%22x3.1%22x8.3%22%29&requestor=studio',
        sku: 'CIM-XKPD8TA9',
        skuVariable: {
            Backside: 'Blank',
            'Paper Stock': 'White kraft',
            Size: 'Small ( 7.5"x3.1"x8.3")',
        },
    },
    {
        products: ['totebags'],
        url: 'https://surfaces.products.vpsvc.com/surfaces/PRD-PY8ZIQNW/20?optionSelections%5BBackside%5D=Blank&optionSelections%5BDecoration%20Technology%5D=Direct-to-Garment&optionSelections%5BSubstrate%20Color%5D=%23171614&requestor=studio',
        sku: 'CIM-0J65H83J',
        skuVariable: {
            Backside: 'Blank',
            'Decoration Technology': 'Direct-to-Garment',
            'Substrate Color': '#171614',
        },
    },
    {
        products: ['productlabels'],
        url: 'https://surfaces.products.vpsvc.com/surfaces/PRD-LDDVZZ2Z/23?optionSelections%5BFinish%5D=Glossy&optionSelections%5BMaterial%5D=White%20Paper&optionSelections%5BProduct%20Orientation%5D=Horizontal&optionSelections%5BShape%5D=Circle&optionSelections%5BSize%5D=3%22x3%22&requestor=studio',
        sku: 'CIM-MK50GZPX',
        skuVariable: { Shape: 'Circle', Size: '3"x3"', Material: 'White Paper', 'Product Orientation': 'Horizontal' },
        mpv: 'productLabels',
        productKey: 'PRD-LDDVZZ2Z',
    },
    {
        products: ['notecards'],
        url: 'https://surfaces.products.vpsvc.com/surfaces/PRD-T2ZQMC0H/31?optionSelections%5BBackside%5D=Color&optionSelections%5BFoil%20Color%5D=None&optionSelections%5BFold%5D=Flat&optionSelections%5BPaper%20Stock%5D=Standard%20Matte%20-%20271gsm%20%2814pt%29%20Uncoated&optionSelections%5BProduct%20Orientation%5D=Horizontal&optionSelections%5BSize%5D=5.5%22%20x%204%22&optionSelections%5BTrim%5D=Standard&requestor=studio',
        sku: 'CIM-HVXQV2MX',
        skuVariable: {
            Backside: 'Color',
            'Foil Color': 'None',
            Fold: 'Flat',
            'Paper Stock': 'Standard Matte - 271gsm (14pt) Uncoated',
            'Product Orientation': 'Horizontal',
            Size: '5.5" x 4"',
            Trim: 'Standard',
        },
    },
    {
        products: ['caps'],
        url: 'https://surfaces.products.vpsvc.com/surfaces/PRD-OVINFUUGV/14?optionSelections%5BSubstrate%20Color%5D=%23003a8d&requestor=studio',
        sku: 'CIM-TQ4Q2APZ',
        skuVariable: { 'Substrate Color': '#042068' },
        mpv: 'printedBaseballCaps',
        productKey: 'PRD-OVINFUUGV',
    },
];

export function getSurfaceSkuForProduct(product: string) {
    const mapping = PRODUCT_TO_SURFACE_MAP.find((x) => x.products.includes(product));
    if (!mapping) return '';
    return mapping.sku;
}

export function getSurfaceSkuVariablesForProduct(product: string) {
    const mapping = PRODUCT_TO_SURFACE_MAP.find((x) => x.products.includes(product));
    if (!mapping) return {};
    return mapping.skuVariable;
}

export const DEFAULT_SURFACE_BC_8x5 =
    'https://surface.products.cimpress.io/v3/calculatedsurfacesets/CIM-666QNK0Y?variables=%7B%22Finish%22%3A%22None%22%2C%22Backsides%22%3A%22Blank%22%2C%22Shape%22%3A%22Standard%22%2C%22Corners%22%3A%22Standard%20Corners%22%2C%22Thickness%22%3A%22Standard%22%2C%22Substrate%22%3A%22Matte%22%2C%22Product%20Orientation%22%3A%22Horizontal%22%7D';

export function getSurfaceUrlForProduct(product: string) {
    const mapping = PRODUCT_TO_SURFACE_MAP.find((x) => x.products.includes(product));
    if (!mapping) return DEFAULT_SURFACE_BC_8x5;
    return mapping.url;
}

export function getMpvIdFromProductName(product: string) {
    const mapping = PRODUCT_TO_SURFACE_MAP.find((x) => x.products.includes(product));
    if (!mapping) return '';
    return mapping.mpv;
}

export function getProductKeyFromProductName(product: string) {
    const mapping = PRODUCT_TO_SURFACE_MAP.find((x) => x.products.includes(product));
    if (!mapping) return '';
    return mapping.productKey;
}
