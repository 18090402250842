import { Button, Select, TextField } from '@cimpress/react-components';
import { useState } from 'react';
import { getPreviewUrl, useDebouncedEffect, useUpdateEffect } from '../../../tools';
import { CropFractionsEditor, ImageUrlSelector, LoadingContainer, type SelectorOption } from '../../common';
import { Control } from '../../layout';
import classes from './ImageAssetEditor.module.css';
import type { ImageAsset } from './types';

export interface ImageAssetEditorProps {
    initialValue?: ImageAsset;
    assetId: string;
    onChange: (assetId: string, value: ImageAsset) => void;
    onRemove: (assetId: string) => void;
}

type ImageState = Partial<ImageAsset>;

const IMAGE_PURPOSE_SELECTOR_OPTIONS: SelectorOption[] = [
    { label: 'None', value: '' },
    { label: 'Logo', value: 'logo' },
    { label: 'Photo', value: 'photo' },
    { label: 'Background', value: 'background' },
];
const LENGTH_REGEX = /^-?([0-9]*(\.[0-9]+)?(?:e[-+]?\d+)?)(twip|px|pt|mm|cm|dm|m|vpx|em|in)$/i;

export const LENGTH_WITHOUT_UNIT_REGEX = /^\d+$/;

function assetToState(imageAsset?: ImageAsset): ImageState {
    if (!imageAsset) {
        return {};
    }

    return {
        originalSourceUrl: imageAsset.originalSourceUrl,
        printUrl: imageAsset.printUrl,
        previewUrl: imageAsset.previewUrl,
        width: imageAsset.width,
        height: imageAsset.height,
        cropFractions: imageAsset.cropFractions,
        purpose: imageAsset.purpose,
    };
}

interface ImageDimensions {
    width: number;
    height: number;
}

async function getImageDimensions(imageUrl: string, signal: AbortSignal): Promise<ImageDimensions | undefined> {
    return new Promise<ImageDimensions | undefined>((resolve) => {
        const image = new Image();

        const abortRequest = () => {
            image.src = '';
            resolve(undefined);
        };

        if (signal.aborted) {
            abortRequest();
        }

        signal.addEventListener('abort', abortRequest);

        image.onload = () => {
            signal.removeEventListener('abort', abortRequest);
            resolve({ width: image.naturalWidth, height: image.naturalHeight });
        };

        image.onerror = () => {
            signal.removeEventListener('abort', abortRequest);
            resolve(undefined);
        };

        image.src = imageUrl;
    });
}

export const ImageAssetEditor = ({ assetId, initialValue, onChange, onRemove }: ImageAssetEditorProps) => {
    const [image, setImage] = useState(() => assetToState(initialValue));

    const [url, setUrl] = useState(image.previewUrl ?? image.printUrl ?? image.originalSourceUrl ?? '');
    const [isImageLoading, setIsImageLoading] = useState(false);

    const [isWidthDirty, setIsWidthDirty] = useState(false);
    const [isHeightDirty, setIsHeightDirty] = useState(false);
    const [purposeSelector, setPurposeSelector] = useState<SelectorOption>(
        IMAGE_PURPOSE_SELECTOR_OPTIONS.find((purpose) => purpose.value === image.purpose) ??
            IMAGE_PURPOSE_SELECTOR_OPTIONS[0],
    );

    const { originalSourceUrl, printUrl, previewUrl, width, height, cropFractions, purpose } = image;

    function handlePurposeChange(purposeSelected: SelectorOption) {
        setPurposeSelector(purposeSelected);
        setImage({ ...image, purpose: purposeSelected.value || undefined });
    }

    function handleWidthChange(width: string) {
        const isValidWidth = width.match(LENGTH_REGEX) || width.match(LENGTH_WITHOUT_UNIT_REGEX);
        console.log({ isValidWidth });
        setIsWidthDirty(!isValidWidth);
        setImage((prev) => ({ ...prev, width: width }));
    }

    function handleHeightChange(height: string) {
        const isValidHeight = height.match(LENGTH_REGEX) || height.match(LENGTH_WITHOUT_UNIT_REGEX);
        setIsHeightDirty(!isValidHeight);
        setImage((prev) => ({ ...prev, height: height }));
    }

    useUpdateEffect(() => {
        if (!(isWidthDirty || isHeightDirty)) {
            const newImage: ImageAsset = {
                originalSourceUrl: originalSourceUrl!,
                printUrl: printUrl!,
                previewUrl: previewUrl!,
                width: `${width}`,
                height: `${height}`,
            };

            if (cropFractions) {
                newImage.cropFractions = cropFractions;
            }

            if (purpose) {
                newImage.purpose = purpose;
            }

            onChange(assetId, newImage);
        }
    }, [
        assetId,
        originalSourceUrl,
        printUrl,
        previewUrl,
        width,
        height,
        cropFractions,
        purpose,
        isWidthDirty,
        isHeightDirty,
        onChange,
    ]);

    useDebouncedEffect(
        () => {
            if (!url) {
                setIsImageLoading(false);
                setImage({});
                return;
            }

            const abortController = new AbortController();

            setIsImageLoading(true);

            Promise.all([
                getPreviewUrl(url, abortController.signal),
                getImageDimensions(url, abortController.signal),
            ]).then(([previewUrl, dimensions]) => {
                if (!abortController.signal.aborted) {
                    setImage({
                        originalSourceUrl: url,
                        printUrl: url,
                        previewUrl,
                        width: dimensions?.width,
                        height: dimensions?.height,
                    });
                    setIsImageLoading(false);
                }
            });

            return () => {
                abortController.abort();
            };
        },
        [url],
        300,
    );

    return (
        <LoadingContainer isLoading={isImageLoading}>
            <Control spacing="compact">
                <ImageUrlSelector
                    url={url}
                    onChange={(value) => setUrl(value ?? '')}
                    // We're doing further processing after the URL is selected, so don't hide the loading overlay just yet
                    setIsImageLoading={(value) => (value ? setIsImageLoading(true) : undefined)}
                />

                <Control spacing="compact" className={classes.columns}>
                    <TextField
                        label="Width"
                        required
                        value={width}
                        status={isWidthDirty ? 'error' : undefined}
                        onChange={(e) => handleWidthChange(e.target.value)}
                    />

                    <TextField
                        label="Height"
                        required
                        value={height}
                        status={isHeightDirty ? 'error' : undefined}
                        onChange={(e) => handleHeightChange(e.target.value)}
                    />
                </Control>

                <Control>
                    <Control.Title>Purpose</Control.Title>
                    <Select
                        options={IMAGE_PURPOSE_SELECTOR_OPTIONS}
                        value={purposeSelector}
                        label="Purpose"
                        onChange={handlePurposeChange}
                    />
                </Control>

                <CropFractionsEditor
                    cropFractions={cropFractions}
                    onChange={(value) => setImage((prev) => ({ ...prev, cropFractions: value }))}
                />

                <Button color="danger" aria-label="Remove image asset" onClick={() => onRemove(assetId)}>
                    Remove Image
                </Button>
            </Control>
        </LoadingContainer>
    );
};
